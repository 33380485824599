import { Dispatch, SetStateAction, useState } from 'react'
import { Button } from 'src/components/ui/button'
import { DialogClose } from 'src/components/ui/dialog'
import { IOrder } from 'src/_models/order.model'
import OrderDocumentStatus from 'src/components/order/document-status'
import OrderSignatureGuaranteeView from 'src/components/order/signature-guarantee-view'
import TotalPayableAmount from 'src/components/order/total-payable-amount'
import { InputNumber } from 'antd'
import { Input } from 'src/components/ui/input'
import currencyFormatter from 'src/components/ui/currency-formatter'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { toast } from 'src/components/ui/use-toast'
import { useTranslation } from 'react-i18next'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
} from 'src/components/ui/alert-dialog'

const BuyerOrderShippingDocument = ({ goNext, order }: { goNext: () => void; order: IOrder }) => {
  const { t } = useTranslation('translation')
  const queryClient = useQueryClient()
  const [showRedirectionAlertUrl, setShowRedirectionAlertUrl] = useState<string | null>(null)
  const paymentForShippingFeesToCustomerMutation = useMutation({
    mutationFn: ({ orderId }: { orderId: number }) => {
      const token = localStorage.getItem('token')
      return axios.post(
        //tODO -> incorrect api is used here
        `/api/buyer/order/payout/${orderId}/shipping`,
        {},
        {
          // responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const paymentForShippingFeesToCustomer = () => {
    paymentForShippingFeesToCustomerMutation.mutate(
      { orderId: order.id },
      {
        onSuccess: () => {
          toast({
            title: 'Shipping Payment done.',
          })
          queryClient.invalidateQueries()
          goNext()
        },
        onError: (error) => {
          const axiosError = error as AxiosError<{ message: string; redirectUrl?: string }>

          if (axiosError.response?.data?.redirectUrl) {
            setShowRedirectionAlertUrl(axiosError.response?.data?.redirectUrl)
            return
          }

          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: t(axiosError.response?.data?.message || 'Shipping Payment failed.'),
          })
        },
      },
    )
  }

  return (
    <>
      <div className="flex max-h-[calc(90vh-538px)] flex-col overflow-y-scroll px-[30px]">
        <OrderDocumentStatus order={order} />
        <div className="flex flex-row items-center justify-between pl-[28px]">
          <div className="flex gap-[6px] text-sm font-normal 2xl:text-base">Shipping Amount</div>
          <div className="w-[170px] rounded-[10px] bg-[#F2F2F2] px-[30px] py-4 text-right font-extrabold">
            {currencyFormatter({
              currency: order.auction.sellingCurrency,
              amount: order.shippingCost,
              cents: true,
            })}
          </div>
        </div>
      </div>
      <div className="max-h-40 px-[30px]">
        <hr className=" mt-[10px] h-px border-0 bg-[#E0E0E0]" />
        <TotalPayableAmount order={order} />
        <div className="flex gap-5">
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button
            disabled={
              order.status === 'SHIPPING_PAID' ||
              !order.shippingCost ||
              paymentForShippingFeesToCustomerMutation.isLoading
            }
            onClick={paymentForShippingFeesToCustomer}
          >
            Deposit Shipping Amount
          </Button>
        </div>
        {/* Alert to open url for payment */}
        <AlertDialog
          open={!!showRedirectionAlertUrl}
          onOpenChange={(value) => setShowRedirectionAlertUrl(value ? showRedirectionAlertUrl : null)}
        >
          <AlertDialogContent className="rounded-[40px]">
            <AlertDialogHeader>
              {/*<AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>*/}
              <AlertDialogDescription>
                You will be redirected to paykeeper to pay for the payment.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction
                onClick={() => showRedirectionAlertUrl && window.open(showRedirectionAlertUrl, '_self')}
              >
                Continue
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </>
  )
}

export default BuyerOrderShippingDocument
