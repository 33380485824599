import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { formatAuctionDate, formatRecentUpdateDate, formatRecentUpdateTime } from 'src/lib/format-auction'
import { IRecentUpdate } from 'src/_models/recent-update.model'
import { useSearchParams } from 'react-router-dom'
import AuctionDialog from 'src/components/auction/auction-dialog'
import { Dialog, DialogContent } from 'src/components/ui/dialog'
import OrderDialog from 'src/components/order/order-dialog'
export default function StickyRightComponent({ type }: { type: 'FUEL_TRADING' | 'GREEN_TRACING' | 'REC' }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const recentUpdatesQuery = useQuery({
    queryKey: [`recentUpdates-${type}`],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get(`/api/recent-updates?type=${type}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }) as Promise<AxiosResponse<{ recentUpdates: IRecentUpdate[] }>>
    },
    cacheTime: 60,
  })

  const platformUpdatesQuery = useQuery({
    queryKey: [`platformUpdates-${type}`],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get(`/api/platform-updates?type=${type}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }) as Promise<
        AxiosResponse<{
          platformUpdates: {
            id: number
            message: string
            urlLink: string
            createdAt: string
          }[]
        }>
      >
    },
    cacheTime: 60,
  })

  const recentUpdateId = searchParams.get('recent-update')
  const [selectedRecentUpdate, setSelectedRecentUpdate] = useState<IRecentUpdate | null>(null)
  const [showDialog, setShowDialog] = useState(false)
  const [showPlatform, setShowPlatform] = useState(false)
  const [showRecent, setShowRecent] = useState(!!recentUpdateId)
  const { t } = useTranslation()
  const showMarcketTab = (value: boolean) => {
    if (value) {
      closeSticky()
    }
    setShowPlatform(!showPlatform)
    setShowRecent(false)
  }
  const showRecentTab = (value: boolean) => {
    if (value) {
      closeSticky()
    }
    setShowRecent(!showRecent)
    setShowPlatform(false)
  }
  const closeSticky = () => {
    setShowRecent(false)
    setShowPlatform(false)
  }

  useEffect(() => {
    if (recentUpdateId && recentUpdatesQuery.data && !showDialog) {
      const recentUpdate = recentUpdatesQuery.data.data.recentUpdates.find(
        (update) => update.auction.generatedId === recentUpdateId,
      )
      console.log('recentUpdate', recentUpdate)
      setSelectedRecentUpdate(recentUpdate || null)
      setShowDialog(true)
    }
  }, [recentUpdateId, recentUpdatesQuery.data])

  return (
    <>
      {selectedRecentUpdate && (
        <Dialog
          open={showDialog}
          onOpenChange={(e) => {
            setShowDialog(e)
            if (!e) {
              setSearchParams({ 'recent-update': '' })
            }
          }}
        >
          {selectedRecentUpdate.fuelTradingOrder || selectedRecentUpdate.auction.fuelTradingOrders.length > 0 ? (
            <DialogContent className="mx-auto h-auto max-h-[90vh] w-full max-w-[741px] overflow-y-scroll rounded-[40px] bg-white px-0 py-[30px]">
              <OrderDialog
                orderId={
                  selectedRecentUpdate.fuelTradingOrder?.id || selectedRecentUpdate.auction.fuelTradingOrders[0].id
                }
                setShowOrderStatusModal={(showDialog) => setShowDialog(showDialog)}
              />
            </DialogContent>
          ) : (
            <DialogContent className="max-h-5/6 h-auto w-full max-w-[723px] overflow-y-auto rounded-[40px] p-0">
              <AuctionDialog
                auction={selectedRecentUpdate.auction}
                setShowDialog={(showDialog) => {
                  setShowDialog(showDialog)
                  setSearchParams('')
                }}
              />
            </DialogContent>
          )}
        </Dialog>
      )}
      <section>
        <div
          className={
            showPlatform || showRecent
              ? 'col fixed right-[348px] top-[325px] z-20 flex rotate-90 items-center gap-2 align-middle 2xl:right-[332px]'
              : 'col fixed right-[-169px] top-[290px] z-20 flex rotate-90 items-center gap-2 align-middle 2xl:right-[-183px] 2xl:top-[320px]'
          }
        >
          <div
            onClick={() => showMarcketTab(!showPlatform)}
            className={
              !showPlatform
                ? 'line-height relative cursor-pointer rounded-b-lg  border-2 border-transparent bg-[#FB8A2E] px-5 py-2 text-sm font-bold text-white 2xl:py-3 2xl:text-base'
                : 'line-height relative cursor-pointer rounded-b-lg border-2 border-solid border-white bg-[#FB8A2E] px-5 py-2 text-sm font-bold text-white 2xl:py-3 2xl:text-base'
            }
          >
            PLATFORM UPDATES
          </div>

          <div
            onClick={() => showRecentTab(!showRecent)}
            className={
              !showRecent
                ? 'line-height relative z-20 cursor-pointer rounded-b-lg border-2 border-transparent bg-[#023047] px-5  py-2 text-sm font-bold text-white 2xl:py-3 2xl:text-base'
                : 'line-height relative cursor-pointer rounded-b-lg border-2 border-solid border-white bg-[#023047] px-5 py-2 text-sm font-bold text-white 2xl:py-3  2xl:text-base'
            }
          >
            RECENT UPDATES
          </div>
        </div>
        {(showPlatform || showRecent) && (
          <>
            <div
              className="z-9990 fixed left-0 top-0 z-[10] h-full w-full overflow-y-auto overflow-x-hidden bg-black/30 outline-none"
              id="exampleModalCenter"
              aria-labelledby="exampleModalCenterTitle"
              aria-modal="true"
              role="dialog"
              onClick={() => closeSticky()}
            >
              <div className="pointer-events-none  relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]">
                <div className="pointer-events-auto relative flex w-full flex-col rounded-[20px] border-none bg-white bg-clip-padding text-current shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] outline-none"></div>
              </div>
            </div>
            <div className="bg-opacity-8 fixed right-0 top-[100px] z-30 h-[80%] w-[522px] rounded-bl-xl rounded-tl-xl  bg-white p-5 shadow-md overflow-y-scroll">
              <div className="relative p-[10px]">
                {showPlatform &&
                  platformUpdatesQuery.data &&
                  platformUpdatesQuery.data.data &&
                  (platformUpdatesQuery.data.data.platformUpdates.length === 0 ? (
                    <div className="text-center">No Updates</div>
                  ) : (
                    platformUpdatesQuery.data.data.platformUpdates.map((platformUpdate) => {
                      return (
                        <div
                          onClick={() => window.open(platformUpdate.urlLink, '_blank')}
                          className="flex w-full flex-col cursor-pointer"
                        >
                          <div className="mb-[10px] flex justify-between">
                            {/*<div className="flex items-center gap-[5px] align-middle">*/}
                            {/*<span className="font-bold text-primary xl:text-[10px]">ID# TQ-H-000000001</span>*/}
                            {/*</div>*/}
                            <div className="text-xs font-bold text-secoundary 2xl:text-sm">
                              {formatAuctionDate(platformUpdate.createdAt)}
                            </div>
                          </div>
                          <div className="text-xs font-medium text-secoundary 2xl:text-sm">
                            {/*<span className="text-xs font-bold text-secoundary 2xl:text-sm">Green Ammonia</span>*/}
                            {/*<span className="font-bold text-primary xl:text-[10px]">99.9995%</span> */}
                            {platformUpdate.message}
                          </div>

                          <hr className="my-5 h-[1px] w-full bg-gray2" />
                        </div>
                      )
                    })
                  ))}
                {showRecent &&
                  recentUpdatesQuery.data &&
                  recentUpdatesQuery.data.data &&
                  (recentUpdatesQuery.data.data.recentUpdates.length === 0 ? (
                    <>No Updates</>
                  ) : (
                    recentUpdatesQuery.data.data.recentUpdates.map((recentUpdate) => {
                      return (
                        <div
                          key={recentUpdate.id}
                          className="flex w-full flex-col cursor-pointer"
                          onClick={() => {
                            setSelectedRecentUpdate(recentUpdate)
                            setShowDialog(true)
                          }}
                        >
                          <div className="mb-[10px] flex justify-between">
                            <div className="flex items-center gap-[5px] align-middle">
                              <span className="text-sm font-bold text-primary">
                                ID# {recentUpdate.auction.generatedId}
                              </span>
                            </div>
                            <div className="text-xs font-bold text-secoundary 2xl:text-sm">
                              {formatRecentUpdateDate(recentUpdate.createdAt)}&nbsp; &nbsp;
                              {formatRecentUpdateTime(recentUpdate.createdAt)}
                            </div>
                          </div>
                          <div className="text-xs font-medium text-secoundary 2xl:text-sm">
                            <span className="text-xs font-bold text-secoundary 2xl:text-sm">
                              {recentUpdate.auction.fuelColor.name} {recentUpdate.auction.fuelType.name}
                            </span>
                            <span className="pl-1 pr-2 text-xs font-bold text-secoundary 2xl:text-sm">
                              {recentUpdate.auction.fuelPurityLevel.purity}
                            </span>{' '}
                            {recentUpdate.message}
                          </div>

                          <hr className="my-2.5 h-[1px] w-full bg-gray2" />
                        </div>
                      )
                    })
                  ))}
              </div>
            </div>
          </>
        )}
      </section>
    </>
  )
}
